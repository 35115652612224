import { Fragment } from 'react'
import { Divider } from 'react-native-paper'
import { useAppStore } from '../../store/useAppStore'
import trpc from '../../utils/trpc'
import SearchItem from './SearchItem'

interface Props {
  searchInput: string
}

const SearchResults = (props: Props) => {
  const { searchInput } = props

  const currentOrganization = useAppStore.use.currentOrganization()

  const searchQuery = trpc.search.useQuery(
    {
      organizationId: currentOrganization.id || '',
      query: searchInput || '',
    },
    {
      enabled: !!searchInput && !!currentOrganization.id,
    }
  )

  return (
    <>
      {searchQuery.data?.users?.map((user) => (
        <Fragment key={user.id + '-view'}>
          <SearchItem
            id={user.id}
            key={user.id}
            path={`users/${user.id}`}
            primaryTitle={`${user.firstName} ${user.lastName}`}
          />
          <Divider />
        </Fragment>
      ))}
      {searchQuery.data?.fosters?.map((foster, index) => (
        <Fragment key={foster.id + '-view'}>
          <SearchItem
            extraData={[
              ...[
                foster.microchipId
                  ? `Microchip : ${foster.microchipId}`
                  : undefined,
              ],
            ]}
            id={foster.id}
            key={foster.id}
            path={`fosters/${foster.id}`}
            primaryTitle={foster.name}
            secondaryTitle={foster.userFullName}
            thumbUri={foster.thumbUri}
          />
          {index < searchQuery.data.fosters.length - 1 && (
            <Divider key={foster.id + '-divider'} />
          )}
        </Fragment>
      ))}
    </>
  )
}

export default SearchResults
