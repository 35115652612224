import { isValid, parse } from 'date-fns'
import { useState } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
} from 'react-hook-form'
import { Platform, View } from 'react-native'
import DateTimePickerModal from 'react-native-modal-datetime-picker'
import {
  HelperText,
  Modal,
  Portal,
  TextInput,
  TextInputProps,
} from 'react-native-paper'
import DateTimePicker from 'react-native-ui-datepicker'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Skeleton from '../shared/Skeleton'
import { getErrorField } from './formUtils'

interface Props<C extends FieldValues, E extends FieldErrors> {
  fieldName: Path<C>
  required: boolean
  errors?: E
  control: Control<C, any>
  isLoading?: boolean
  onBlur?: () => void
}

const FormFieldDate = <C extends FieldValues, E extends FieldErrors>({
  fieldName,
  required,
  errors,
  control,
  style,
  isLoading,
  onBlur,
  ...props
}: Props<C, E> & TextInputProps) => {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const { styles } = useStyles(stylesheet)
  const { theme } = useStyles()

  return (
    <View style={style}>
      {/* <Skeleton show={isLoading}> */}
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value } }) => (
          <View {...props}>
            <TextInput
              testID={`${fieldName}-dateInput`}
              {...props}
              blurOnSubmit
              error={!!getErrorField(fieldName, errors)}
              mode="outlined"
              onBlur={() => {
                if ((value as unknown as Date) instanceof Date) {
                  onChange(value)
                } else if (typeof value === 'string') {
                  const text = value?.trim()

                  if (text === '') {
                    onChange(undefined)
                    return
                  }
                  const date = parseFlexibleDate(text)
                  if (date) {
                    onChange(date)
                  } else {
                    onChange('')
                  }
                }

                onBlur?.()
              }}
              onChangeText={onChange}
              right={
                <TextInput.Icon
                  color={theme.colors.secondary}
                  icon="calendar"
                  onPress={() => {
                    setShowDatePicker(true)
                  }}
                  testID={`calendar-icon-${fieldName}`}
                />
              }
              style={styles.root}
              value={displayValue(value)}
            />
            {Platform.OS === 'web' && showDatePicker && (
              <Portal>
                <Modal
                  contentContainerStyle={{
                    backgroundColor: 'white',
                    width: '45%',
                    alignSelf: 'center',
                  }}
                  onDismiss={() => setShowDatePicker(false)}
                  visible={showDatePicker}
                >
                  <View
                    style={{
                      padding: 10,
                      flex: 1,
                      backgroundColor: '#F5FCFF',
                    }}
                  >
                    <DateTimePicker
                      date={value || new Date()}
                      mode="single"
                      onChange={(params) => {
                        if (params.date) {
                          setShowDatePicker(false)
                          onChange(new Date(params.date.toLocaleString()))
                          onBlur?.()
                        }
                      }}
                    />
                  </View>
                </Modal>
              </Portal>
            )}
            {(Platform.OS === 'ios' || Platform.OS === 'android') && (
              <DateTimePickerModal
                isVisible={showDatePicker}
                mode="date"
                onCancel={() => setShowDatePicker(false)}
                onConfirm={(date) => {
                  onChange(date)
                  setShowDatePicker(false)
                }}
              />
            )}
            {getErrorField(fieldName, errors) && (
              <HelperText
                padding="normal"
                testID={`${fieldName}-error`}
                type="error"
                visible={!!getErrorField(fieldName, errors)}
              >
                {getErrorField(fieldName, errors)?.message as string}
              </HelperText>
            )}
          </View>
        )}
        rules={{
          required: required,
        }}
      />
      {/* </Skeleton> */}
    </View>
  )
}

function displayValue(value: Date | null) {
  if (!value) return ''

  try {
    return value.toISOString().split('T')[0]
  } catch {
    return value.toString()
  }
}

const stylesheet = createStyleSheet((theme) => ({
  root: {
    backgroundColor: theme.colors.surface,
  },
}))

function parseFlexibleDate(dateString: string): Date | null {
  const formats = [
    'yyyy-MM-dd',
    'dd-MM-yyyy',
    'MM-dd-yyyy',
    'MM/dd/yyyy',
    'dd/MM/yyyy',
    'yyyy/MM/dd',
    'dd.MM.yyyy',
    'MM.dd.yyyy',
    'MMMM d, yyyy',
    'd MMMM yyyy',
    'yyyy-MM-dd HH:mm:ss',
    'dd-MM-yyyy HH:mm:ss',
  ]

  for (const format of formats) {
    const date = parse(dateString, format, new Date())
    if (isValid(date)) {
      return date
    }
  }

  return null
}

export default FormFieldDate
