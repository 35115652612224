import { useNavigation } from 'expo-router'
import { useEffect } from 'react'
import { View } from 'react-native'
import Page from '../../../components/shared/Page'
import DeactivatedBanner from '../../../components/users/detail/DeactivatedBanner'
import UserDetailForm from '../../../components/users/detail/UserDetailForm'
import UserDetailHeaderContent from '../../../components/users/detail/UserDetailHeaderContent'

const UserDetail = () => {
  const navigation = useNavigation()

  useEffect(() => {
    navigation.setOptions({
      title: `Edit User`,
      headerBackVisible: true,
      headerRight,
    })
  }, [navigation])

  return (
    <View>
      <Page viewId="user-detail-scroll-view">
        <DeactivatedBanner />
        <UserDetailForm />
      </Page>
    </View>
  )
}
const headerRight = () => <UserDetailHeaderContent />

export default UserDetail
