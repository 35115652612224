import { Control, FormState } from 'react-hook-form'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Species from '../../../enums/species'
import FosterService from '../../../services/fosterService'
import FormFieldCheckbox from '../../form/FormFieldCheckbox'
import FormFieldDate from '../../form/FormFieldDate'
import FormFieldText from '../../form/FormFieldText'
import Card from '../../shared/Card'
import { FosterForm } from '../fosterFormSchema'
import fosterSharedStyles from './fosterSharedStyles'

interface Props {
  control: Control<FosterForm, any>
  formState: FormState<FosterForm>
  submit: () => void
}

const FosterMedicalCard = ({ control, formState, submit }: Props) => {
  const { styles } = useStyles(stylesheet)
  const { styles: sharedStyles } = useStyles(fosterSharedStyles)

  const { fosterId } = FosterService.useCurrentFoster()
  const { foster, isLoading } = FosterService.useFoster(fosterId)

  return (
    <View>
      <Card title="Medical Info">
        <View style={sharedStyles.textInputContainer}>
          {foster?.species === Species.Dog && (
            <>
              <Text style={styles.medicalInfoText}>
                Puppies should be dewormed for roundworms at 2 weeks old, 4
                weeks old, 6 weeks old, and 8 weeks old. A fecal sample should
                also be dropped off at your veterinarian during their first
                vaccines to check for any other parasites. This deworming
                schedule also applies for the mom if you have them.
              </Text>
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="positiveFecalTestDate"
                isLoading={isLoading}
                label="💩 Positive Fecal Test Date"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="fecalFollowUpDate"
                isLoading={isLoading}
                label="💩 Fecal Follow-up Test Due"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="clearFecalTestDate"
                isLoading={isLoading}
                label="✔️ Clear Fecal Test Date"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <View style={styles.medicalInfoInput}>
                <FormFieldDate
                  control={control}
                  disabled={isLoading}
                  errors={formState.errors}
                  fieldName="heartwormTestingCompletedDate"
                  isLoading={isLoading}
                  label="✔️ Heartworm Test Completed"
                  onBlur={submit}
                  required={false}
                  style={styles.medicalInfoInput}
                />

                <FormFieldCheckbox
                  control={control}
                  fieldName="heartwormPositive"
                  formState={formState}
                  isLoading={isLoading}
                  label="Heartworm Positive"
                  onBlur={submit}
                  required={false}
                  status="checked"
                />
              </View>
              <Text style={styles.medicalInfoText}>
                Heartworm & flea/tick prevention should be given every 30 days
                year-round, starting at 9 weeks old. Spay/neuter surgery is
                usually done at 9 weeks and when the puppy is at least 2 lbs.
              </Text>
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="nextHeartwormPreventionDue"
                isLoading={isLoading}
                label="💊 Next Heartworm Med Due"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="nextFleaTickMedDue"
                isLoading={isLoading}
                label="💊 Next Flea/Tick Med Due"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <View style={styles.medicalInfoInput}>
                <FormFieldDate
                  control={control}
                  disabled={isLoading}
                  errors={formState.errors}
                  fieldName="fixedOnDate"
                  isLoading={isLoading}
                  label="✂️ Spay/Neuter Date"
                  onBlur={submit}
                  required={false}
                  style={styles.medicalInfoInput}
                />

                <FormFieldCheckbox
                  control={control}
                  fieldName="fixedBeforeIntake"
                  formState={formState}
                  isLoading={isLoading}
                  label="Fixed before intake"
                  onBlur={submit}
                  required={false}
                  status="checked"
                />
              </View>
              <FormFieldText
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="microchipId"
                isLoading={isLoading}
                keyboardType="numeric"
                label="🏷️ MicrochipId"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
            </>
          )}
          {foster?.species === Species.Cat && (
            <>
              <Text style={styles.medicalInfoText}>
                Kittens should be dewormed for roundworms at 2 weeks old, 4
                weeks old, 6 weeks old, and 8 weeks old. A fecal sample should
                also be dropped off at your veterinarian during their first
                vaccines to check for any other parasites. This deworming
                schedule also applies for the mom if you have them.
              </Text>
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="positiveFecalTestDate"
                isLoading={isLoading}
                label="💩 Positive Fecal Test Date"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="fecalFollowUpDate"
                isLoading={isLoading}
                label="💩 Fecal Follow-up Test Due"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="clearFecalTestDate"
                isLoading={isLoading}
                label="✔️ Clear Fecal Test Date"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <Text style={styles.medicalInfoText}>
                Spay/neuter surgery is usually done around 11/12 weeks of age
                and when the kitten is at least 2 lbs.
              </Text>
              <View style={styles.medicalInfoInput}>
                <FormFieldDate
                  control={control}
                  disabled={isLoading}
                  errors={formState.errors}
                  fieldName="comboTestingCompletedDate"
                  isLoading={isLoading}
                  label="✔️ Combo Test Completed"
                  onBlur={submit}
                  required={false}
                  style={styles.medicalInfoInput}
                />
                <View style={styles.medicalInfoCatComboCheckboxContainer}>
                  <FormFieldCheckbox
                    control={control}
                    fieldName="fivPositive"
                    formState={formState}
                    isLoading={isLoading}
                    label="FIV Positive"
                    onBlur={submit}
                    required={false}
                    status="checked"
                  />
                  <FormFieldCheckbox
                    control={control}
                    fieldName="felvPositive"
                    formState={formState}
                    isLoading={isLoading}
                    label="FeLV Positive"
                    onBlur={submit}
                    required={false}
                    status="checked"
                  />
                </View>
              </View>
              <FormFieldDate
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="nextComboMedDue"
                isLoading={isLoading}
                label="💊 Next Meds Due"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
              <View style={styles.medicalInfoInput}>
                <FormFieldDate
                  control={control}
                  disabled={isLoading}
                  errors={formState.errors}
                  fieldName="fixedOnDate"
                  isLoading={isLoading}
                  label="✂️ Spay/Neuter Date"
                  onBlur={submit}
                  required={false}
                  style={styles.medicalInfoInput}
                />

                <FormFieldCheckbox
                  control={control}
                  fieldName="fixedBeforeIntake"
                  formState={formState}
                  isLoading={isLoading}
                  label="Fixed before intake"
                  onBlur={submit}
                  required={false}
                  status="checked"
                />
              </View>
              <FormFieldText
                control={control}
                disabled={isLoading}
                errors={formState.errors}
                fieldName="microchipId"
                isLoading={isLoading}
                keyboardType="numeric"
                label="🏷️ MicrochipId"
                onBlur={submit}
                required={false}
                style={styles.medicalInfoInput}
              />
            </>
          )}
        </View>
      </Card>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    medicalInfoCatComboCheckboxContainer: {
      flexDirection: 'row',
    },
    medicalInfoInput: {
      flexBasis: {
        xs: '50%',
        sm: '40%',
        lg: '20%',
      },
      flexGrow: 1,
      minWidth: 150,
    },
    medicalInfoText: {
      width: '100%',
    },
  }
})

export default FosterMedicalCard
