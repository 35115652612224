import { BottomSheetView } from '@gorhom/bottom-sheet'
import { Dropdown } from 'react-native-element-dropdown'
import { Button } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Species from '../../enums/species'
import { useAppStore } from '../../store/useAppStore'
import GridFilterSimpleSwitch from './GridFilterBasicSwitch'

const GridFilterView = () => {
  const { styles } = useStyles(stylesheet)

  const basicFilters = useAppStore.use.basicFilters()
  const resetBasicFilters = useAppStore.use.resetBasicFilters()
  const setBasicFilters = useAppStore.use.setBasicFilters()

  const advancedFilters = useAppStore.use.advancedFilters()
  const setAdvancedFilters = useAppStore.use.setAdvancedFilters()

  return (
    <BottomSheetView style={styles.root}>
      <Button
        disabled={!basicFilters.isDirty}
        icon="restore"
        onPress={() => resetBasicFilters()}
        style={styles.resetButton}
      >
        Reset Filters
      </Button>
      <Button
        icon="more"
        onPress={() => {
          setBasicFilters({
            ...basicFilters,
            isActive: false,
          })
          setAdvancedFilters({
            ...advancedFilters,
            isActive: true,
          })
        }}
        style={styles.resetButton}
      >
        Advanced Filters
      </Button>
      <>
        <Dropdown
          data={[
            { label: 'Dog', value: Species.Dog },
            { label: 'Cat', value: Species.Cat },
          ]}
          itemTextStyle={styles.text}
          labelField={'label'}
          onChange={(selected) => {
            setBasicFilters({
              ...basicFilters,
              filters: {
                ...basicFilters.filters,
                species: selected.value,
              },
            })
          }}
          placeholder="Species"
          placeholderStyle={styles.text}
          selectedTextStyle={styles.text}
          style={styles.filterContainer}
          value={basicFilters.filters.species}
          valueField={'value'}
        />
        <GridFilterSimpleSwitch
          filter="currentlyFostered"
          label="Currently Fostered"
          style={styles.filterContainer}
          textStyle={styles.text}
        />
        <GridFilterSimpleSwitch
          filter="readyForAdoption"
          label="Ready for Adoption"
          style={styles.filterContainer}
          textStyle={styles.text}
        />
        <GridFilterSimpleSwitch
          filter="includeDeceased"
          label="Include Deceased"
          style={styles.filterContainer}
          textStyle={styles.text}
        />
        <GridFilterSimpleSwitch
          filter="viewAll"
          label="View All"
          style={styles.filterContainer}
          textStyle={styles.text}
        />
      </>
    </BottomSheetView>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    filterContainer: {
      alignItems: 'center',
      borderRadius: theme.tokens.containerBorderRadius,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: theme.tokens.spacing[4],
    },
    resetButton: {
      alignSelf: 'flex-end',
    },
    root: {
      paddingBottom: theme.tokens.spacing[6],
      paddingHorizontal: theme.tokens.spacing[6],
    },
    text: {
      fontSize: 18,
    },
  }
})

export default GridFilterView
