import { DrawerNavigationOptions } from '@react-navigation/drawer'
import { getHeaderTitle } from '@react-navigation/elements'
import { RouteProp } from '@react-navigation/native'
import { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import { router, useNavigation } from 'expo-router'
import { useState } from 'react'
import { Platform, View } from 'react-native'
import { Appbar, IconButton } from 'react-native-paper'
import { useStyles } from 'react-native-unistyles'
import useShouldHideDrawer from '../../hooks/useShouldHideDrawer'
import { useAppStore } from '../../store/useAppStore'
import GridFilterSheet from '../filtering/GridFilterSheet'
import DesktopSearchBar from './DesktopSearchBar'

const Header = ({
  route,
  title,
  options,
}: {
  route: RouteProp<Record<string, object | undefined>, string>
  title?: string
  options: DrawerNavigationOptions | NativeStackNavigationOptions
}) => {
  const { theme } = useStyles()
  const shouldHideDrawer = useShouldHideDrawer()
  const navigation = useNavigation()
  const drawerStore = useAppStore.use.drawer()
  const [filterSheetOpen, setFilterSheetOpen] = useState(false)
  const basicFilters = useAppStore.use.basicFilters()
  const advancedFilters = useAppStore.use.advancedFilters()

  const showBackButton =
    navigation.canGoBack() &&
    !!(options as NativeStackNavigationOptions)?.headerBackVisible

  const showMenuButton =
    !showBackButton && (shouldHideDrawer || !drawerStore.isPinned)

  const dirtyFilters =
    (advancedFilters.isActive && advancedFilters.isDirty) ||
    (basicFilters.isActive && basicFilters.isDirty)

  return (
    <Appbar.Header
      elevated={false}
      style={{
        paddingLeft:
          showBackButton || showMenuButton ? 0 : theme.tokens.spacing[4],
        paddingRight: headerXPadding,
        backgroundColor: theme.colors.background,
      }}
    >
      <>
        {showMenuButton && (
          <IconButton
            icon="menu"
            iconColor={theme.colors.primary}
            onPress={() => {
              // @ts-expect-error the outer stack is a Drawer, so our Stack view can also open the outer drawer
              navigation.openDrawer()
            }}
          />
        )}

        {showBackButton && (
          <IconButton
            icon="arrow-left"
            iconColor={theme.colors.primary}
            onPress={() => {
              navigation.goBack()
            }}
            testID="back-icon-button"
          />
        )}
      </>

      <View style={{ flex: 1, flexDirection: 'row' }}>
        <Appbar.Content
          style={{ flex: Platform.OS === 'web' ? 0.4 : 1 }}
          title={title || getHeaderTitle(options, route.name)}
        />

        {Platform.OS === 'web' && (
          <View
            style={{
              alignItems: 'center',
              bottom: -18,
              left: '50%',
              position: 'absolute',
              transform: [{ translateX: '-50%' }],
              width: '100%',
            }}
          >
            <DesktopSearchBar />
          </View>
        )}
      </View>

      {options.headerRight && (
        <>{options.headerRight({ canGoBack: showBackButton })}</>
      )}

      {Platform.OS !== 'web' && (
        <IconButton
          icon="magnify"
          onPress={() => {
            router.push(`fosters/search`)
          }}
          testID="search-icon-button"
        />
      )}
      <IconButton
        icon={dirtyFilters ? 'filter-check' : 'filter-outline'}
        iconColor={dirtyFilters ? theme.colors.primary : undefined}
        onPress={() => {
          setFilterSheetOpen(true)
        }}
        testID="filter-button"
      />
      <GridFilterSheet
        onClose={() => {
          setFilterSheetOpen(false)
        }}
        open={filterSheetOpen}
      />
    </Appbar.Header>
  )
}

const headerXPadding = 16

export default Header
