import { StateCreator } from 'zustand'

export interface DrawerSlice {
  drawer: {
    isPinned: boolean | undefined
  }

  setDrawer: (drawer: { isPinned: boolean | undefined }) => void
}

export const createDrawerSlice: StateCreator<DrawerSlice> = (set) => ({
  drawer: {
    isPinned: true,
  },
  setDrawer: ({ isPinned }) => {
    set({ drawer: { isPinned } })
  },
})
