import { useEffect, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { UnistylesRuntime } from 'react-native-unistyles'

export default function useShouldHideDrawer() {
  const [shouldHideDrawer, setShouldHideDrawer] = useState(false)

  const { width, height } = useWindowDimensions()

  // Check if the screen dimensions have changed (e.g. orientation change, window resize) and return a fresh breakpoint check
  useEffect(() => {
    setTimeout(() => {
      setShouldHideDrawer(
        ['xs', 'sm', 'md'].includes(UnistylesRuntime.breakpoint)
      )
    }, 100)
  }, [width, height])

  return shouldHideDrawer
}
