import { useNavigationContainerRef, useRouter } from 'expo-router'
import { useEffect, useState } from 'react'

const Grid = () => {
  const router = useRouter()
  const [isNavigationReady, setNavigationReady] = useState(false)
  const rootNavigation = useNavigationContainerRef()

  useEffect(() => {
    const unsubscribe = rootNavigation?.addListener('state', () => {
      setNavigationReady(true)
    })
    return function cleanup() {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [rootNavigation])

  useEffect(() => {
    if (isNavigationReady) {
      router.push('/fosters')
    }
  }, [isNavigationReady, router])
}

export default Grid
