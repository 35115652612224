import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  FormState,
  Path,
} from 'react-hook-form'
import { GestureResponderEvent, View } from 'react-native'
import { Checkbox, CheckboxItemProps } from 'react-native-paper'

interface Props<C extends FieldValues, E extends FieldErrors> {
  fieldName: Path<C>
  formState: FormState<C>
  required: boolean
  control: Control<C, any>
  isLoading?: boolean
  onBlur?: () => void
}

const FormFieldCheckbox = <C extends FieldValues, E extends FieldErrors>({
  fieldName,
  formState,
  required,
  control,
  isLoading,
  style,
  onBlur,
  ...props
}: Props<C, E> & CheckboxItemProps) => {
  return (
    <View style={style}>
      {/* <Skeleton key={props.key}> */}
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, onBlur: formOnBlur, value } }) => (
          <View {...props}>
            <Checkbox.Item
              {...props}
              onPress={(e: GestureResponderEvent) => {
                formState.isDirty = true
                onChange(!value)
                formOnBlur()
                onBlur?.()
              }}
              status={value ? 'checked' : 'unchecked'}
            />
          </View>
        )}
        rules={{
          required: required,
        }}
      />
      {/* </Skeleton> */}
    </View>
  )
}

export default FormFieldCheckbox
