import { zodResolver } from '@hookform/resolvers/zod'
import { router } from 'expo-router'
import { FieldErrors, useForm } from 'react-hook-form'
import { KeyboardAvoidingView, View } from 'react-native'
import { Button, Modal, Portal } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import z from 'zod'
import PaymentMethod from '../../../enums/paymentMethod'
import FosterService from '../../../services/fosterService'
import trpc from '../../../utils/trpc'
import FormFieldDate from '../../form/FormFieldDate'
import FormFieldSelect from '../../form/FormFieldSelect'
import FormFieldText from '../../form/FormFieldText'

const adoptionSchema = z.object({
  adoptedByName: z.string(),
  adoptedByEmail: z.string().email(),
  adoptedName: z.string(),
  adoptionDate: z.date(),
  paymentMethod: z.nativeEnum(PaymentMethod),
  returnDate: z.date().optional(),
  returnReason: z.string().optional(),
})

export type AdoptionRecordForm = z.infer<typeof adoptionSchema>

interface Props {
  defaultValues?: {
    id: string
    adoptedByName: string | null
    adoptedByEmail: string | null
    adoptedName: string | null
    adoptionDate: Date | null
    paymentMethod: PaymentMethod | null
    returnDate: Date | null
    returnReason: string | null
  }
  open: boolean
  onClose: () => void
  mutation:
    | ReturnType<typeof trpc.foster.adoptions.create.useMutation>
    | ReturnType<typeof trpc.foster.adoptions.update.useMutation>
}

const AdoptionForm = ({ defaultValues, open, onClose, mutation }: Props) => {
  const { styles } = useStyles(stylesheet)

  const { fosterId } = FosterService.useCurrentFoster()

  const { foster, refreshFoster } = FosterService.useFoster(fosterId)

  const { control, formState, handleSubmit, reset } =
    useForm<AdoptionRecordForm>({
      mode: 'onBlur',
      resolver: zodResolver(adoptionSchema),
      defaultValues: {
        adoptedByName: defaultValues?.adoptedByName || '',
        adoptedByEmail: defaultValues?.adoptedByEmail || '',
        adoptedName: defaultValues?.adoptedName || '',
        adoptionDate: defaultValues?.adoptionDate || new Date(),
        paymentMethod: defaultValues?.paymentMethod || PaymentMethod.Cash,
      },
    })

  const submit = handleSubmit(async (data) => {
    mutation.mutateAsync(
      {
        fosterId,
        organizationId: foster?.organizationId || '',
        id: defaultValues?.id || '',
        data: {
          ...data,
        },
      },
      {
        onSuccess: () => {
          refreshFoster()
          reset()
          onClose()
          router.push(`/fosters/${fosterId}/adoptions`)
        },
      }
    )
  })

  return (
    <Portal>
      <KeyboardAvoidingView
        behavior="height"
        style={{
          height: open ? '100%' : 'auto',
        }}
      >
        <Modal
          contentContainerStyle={styles.root}
          onDismiss={onClose}
          visible={open}
        >
          <View style={styles.containerRow}>
            <FormFieldText
              autoFocus
              blurOnSubmit
              control={control}
              errors={formState.errors}
              fieldName="adoptedByName"
              label="Adopted By"
              required
              style={styles.input}
            />
            <FormFieldText
              blurOnSubmit
              control={control}
              errors={formState.errors}
              fieldName="adoptedByEmail"
              label="Adopter's Email"
              required
              style={styles.input}
            />
          </View>
          <View style={styles.containerRow}>
            <FormFieldDate
              blurOnSubmit
              control={control}
              errors={formState.errors}
              fieldName="adoptionDate"
              label="Adoption Date"
              required
              style={styles.input}
            />
            <FormFieldSelect
              control={control}
              data={Object.values(PaymentMethod).map((value) => ({
                label: value,
                value,
              }))}
              errors={formState.errors as FieldErrors}
              fieldName="paymentMethod"
              label="Payment Method"
              required
              style={[{ marginTop: 3 }, styles.input]}
            />
            <FormFieldText
              blurOnSubmit
              control={control}
              errors={formState.errors}
              fieldName="adoptedName"
              label="Pet Name"
              required
              style={styles.input}
            />
          </View>
          {defaultValues?.id && (
            <View style={styles.containerColumn}>
              <FormFieldDate
                blurOnSubmit
                control={control}
                errors={formState.errors}
                fieldName="returnDate"
                label="Return Date"
                required={false}
              />
              <FormFieldText
                blurOnSubmit
                control={control}
                errors={formState.errors}
                fieldName="returnReason"
                label="Reason for Return"
                required={false}
              />
            </View>
          )}
          <Button
            disabled={mutation.isLoading}
            loading={mutation.isLoading}
            mode="contained"
            onPress={submit}
          >
            {defaultValues ? 'Update' : 'Add Adoption'}
          </Button>
        </Modal>
      </KeyboardAvoidingView>
    </Portal>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    containerColumn: {
      flexDirection: 'column',
      gap: theme.tokens.spacing[3],
    },
    containerRow: {
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      gap: theme.tokens.spacing[3],
    },
    input: {
      flex: {
        md: 0.5,
      },
    },
    root: {
      alignSelf: 'center',
      backgroundColor: theme.colors.background,
      borderRadius: theme.tokens.containerBorderRadius,
      gap: theme.tokens.spacing[3],
      padding: theme.tokens.spacing[6],
      width: {
        xs: '95%',
        sm: '70%',
      },
    },
  }
})

export default AdoptionForm
