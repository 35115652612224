import { getQueryKey } from '@trpc/react-query'
import { useLocalSearchParams } from 'expo-router'
import { useMemo } from 'react'
import useQueryClient from '../hooks/useQueryClient'
import { useAppStore } from '../store/useAppStore'
import trpc from '../utils/trpc'

const FosterService = {
  useDocument() {
    const params = useLocalSearchParams<{ id: string }>()
    const fosterId = params.id || ''

    const currentOrganization = useAppStore.use.currentOrganization()

    const { data: foster, isLoading } = trpc.foster.byId.useQuery({
      id: fosterId,
      organizationId: currentOrganization?.id || '',
    })

    return { foster, isLoading, currentOrganization }
  },

  useDeleteFoster() {
    const { fosterId } = FosterService.useCurrentFoster()
    const { refreshFosterList } = FosterService.useFosterList()

    const fosterDeleteMutation = trpc.foster.delete.useMutation()

    const deleteFoster = async () => {
      await fosterDeleteMutation.mutateAsync({
        id: fosterId,
      })

      return refreshFosterList()
    }

    return {
      deleteFoster,
      isDeleting: fosterDeleteMutation.isLoading,
    }
  },

  useCurrentFoster() {
    const { id: fosterId } = useLocalSearchParams<{ id: string }>()

    return { fosterId }
  },

  useCurrentFosterName() {
    const { fosterId } = FosterService.useCurrentFoster()
    const { foster } = FosterService.useFoster(fosterId)
    const { fosterList } = FosterService.useFosterList()

    const { name } = foster || fosterList.find((f) => f.id === fosterId) || {}

    return name
  },

  useFoster(id: string) {
    const currentOrganization = useAppStore.use.currentOrganization()
    const queryClient = useQueryClient()

    const {
      data: foster,
      isLoading,
      isSuccess,
      refetch,
    } = trpc.foster.byId.useQuery({
      id,
      organizationId: currentOrganization?.id || '',
    })

    return {
      foster,
      isLoading,
      isSuccess,
      refreshFoster: () => {
        queryClient.invalidateQueries(getQueryKey(trpc.foster.byId, { id }))
        return refetch()
      },
    }
  },

  useFosterList() {
    const currentOrganization = useAppStore.use.currentOrganization()
    const basicFilters = useAppStore.use.basicFilters()
    const advancedFilters = useAppStore.use.advancedFilters()

    const queryClient = useQueryClient()

    const {
      data: pages,
      isLoading,
      fetchNextPage,
      refetch,
    } = trpc.foster.list.useInfiniteQuery(
      {
        advancedFilters: advancedFilters.isActive
          ? advancedFilters.filters
          : undefined,
        basicFilters: basicFilters.isActive ? basicFilters.filters : undefined,
        limit: 50,
        organizationId: currentOrganization?.id || '',
      },
      {
        keepPreviousData: true,
        getNextPageParam: (lastPage) => lastPage.nextCursor,
      }
    )

    const fosterList = useMemo(() => {
      return pages?.pages.flatMap((page) => page.items) || []
    }, [pages])

    return {
      fetchNextPage,
      fosterList,
      isLoading,
      refreshFosterList: () => {
        queryClient.invalidateQueries(getQueryKey(trpc.foster.list))
        return refetch()
      },
    }
  },
}

export default FosterService
