import FosterInfoCard from '../../../../components/fosters/cards/FosterInfoCard'
import FosterNotesCard from '../../../../components/fosters/cards/FosterNotesCard'
import { useFosterFormContext } from '../../../../components/fosters/FosterFormContext'
import FosterTab from '../../../../components/fosters/FosterTab'

const FosterInfoTab = () => {
  const { control, formState, submit } = useFosterFormContext()

  return (
    <FosterTab viewId="foster-info-tab">
      <FosterInfoCard control={control} formState={formState} submit={submit} />
      <FosterNotesCard
        control={control}
        formState={formState}
        submit={submit}
      />
    </FosterTab>
  )
}

export default FosterInfoTab
