import { router } from 'expo-router'
import { View } from 'react-native'
import { Avatar, TouchableRipple } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { isBold, stripMarkers } from '../../utils/strings'
import InitialsAvatar from '../navigation/InitialsAvatar'
import StyledSearchTitle from './SearchStyledTitle'

interface Props {
  id: string
  extraData?: (string | undefined)[]
  primaryTitle: string
  path: string
  secondaryTitle?: string | null
  thumbUri?: string | null
}

const avatarSize = 48

const SearchItem = ({
  id,
  primaryTitle,
  path,
  thumbUri,
  secondaryTitle,
  extraData,
}: Props) => {
  const { styles, theme } = useStyles(stylesheet)

  return (
    <TouchableRipple
      accessibilityRole="button"
      borderless={true}
      key={id + '-touchable'}
      onPress={() => {
        router.push(path)
      }}
      style={{
        borderRadius: theme.tokens.containerBorderRadius,
      }}
      testID={`search-item-${primaryTitle.replaceAll('!', '')}`}
    >
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          padding: theme.tokens.spacing[3],
        }}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <StyledSearchTitle id={id} text={primaryTitle} variant="labelLarge" />
          {secondaryTitle && (
            <StyledSearchTitle
              id={id}
              style={{ color: theme.colors.secondary }}
              text={secondaryTitle || ''}
              variant="labelSmall"
            />
          )}
          {extraData
            ?.filter((data) => data && isBold(data)) // Filter to include only data wrapped in <b></b>
            ?.map((data) => {
              return (
                <StyledSearchTitle
                  id={id}
                  style={{ color: theme.colors.secondary }}
                  text={data || ''}
                  variant="labelSmall"
                />
              )
            })}
        </View>
        {thumbUri ? (
          <View>
            <Avatar.Image
              size={avatarSize}
              source={{ uri: thumbUri }}
              style={styles.avatar}
            />
          </View>
        ) : (
          <View>
            <InitialsAvatar
              size={avatarSize}
              string={stripMarkers(primaryTitle)}
              style={styles.avatar}
            />
          </View>
        )}
      </View>
    </TouchableRipple>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    avatar: {
      alignSelf: 'flex-end',
      marginRight: 10,
    },
  }
})

export default SearchItem
