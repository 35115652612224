import FosterAdoptionCard from '../../../../components/fosters/cards/FosterAdoptionsCard'
import FosterTab from '../../../../components/fosters/FosterTab'

const FosterAdoptionsTab = () => {
  return (
    <FosterTab viewId="foster-adoptions-tab">
      <FosterAdoptionCard />
    </FosterTab>
  )
}

export default FosterAdoptionsTab
