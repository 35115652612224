import { useRouter } from 'expo-router'
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import {
  Caption,
  Divider,
  Icon,
  IconButton,
  Surface,
  Text,
  TouchableRipple,
  useTheme,
} from 'react-native-paper'
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { useAppStore } from '../../store/useAppStore'
import trpc from '../../utils/trpc'
import OrganizationAvatar from './OrganizationAvatar'

const AnimatedSurface = Animated.createAnimatedComponent(Surface)

const OrganizationPicker = () => {
  const theme = useTheme()
  const { styles } = useStyles(stylesheet)
  const router = useRouter()

  const currentOrganization = useAppStore.use.currentOrganization()
  const setCurrentOrganization = useAppStore.use.setCurrentOrganization()

  const currentUserQuery = trpc.user.current.useQuery({
    organizationId: currentOrganization.id,
  })

  const organizations = currentUserQuery.data?.organizations.map(
    (org) => org.organization
  )

  const [isOpen, setIsOpen] = useState(false)

  const animationDurationMS = 175
  const animationProgress = useSharedValue(isOpen ? 1 : 0)
  const chevronRotation = useSharedValue(isOpen ? 0 : 1)

  useEffect(() => {
    chevronRotation.value = withTiming(isOpen ? 1 : 0, {
      duration: animationDurationMS,
    })

    if (organizations?.length && !currentOrganization.id) {
      setCurrentOrganization({
        id: organizations?.[0].id,
        logo: organizations?.[0].logo || '',
        name: organizations?.[0].name,
      })
    }
  }, [
    currentOrganization,
    isOpen,
    chevronRotation,
    organizations,
    setCurrentOrganization,
  ])

  const animatedIconStyles = useAnimatedStyle(() => {
    const rotate = interpolate(
      chevronRotation.value,
      [0, 1],
      [360, 180], // Rotate from 360 to 180 degrees
      'clamp'
    )

    return {
      transform: [{ rotate: `${rotate}deg` }],
    }
  })

  const animatedSurfaceStyles = useAnimatedStyle(() => {
    const height = interpolate(
      animationProgress.value,
      [0, 1],
      [60, 267], // Replace with your actual heights
      'clamp'
    )

    return {
      height,
    }
  })

  const animatedTextStyles = useAnimatedStyle(() => {
    const opacity = interpolate(
      animationProgress.value,
      [0, 0.6, 1],
      [0, 0, 1],
      'clamp'
    )

    return {
      opacity,
    }
  })

  const toggleIsOpen = () => {
    animationProgress.value = withTiming(isOpen ? 0 : 1, {
      duration: animationDurationMS,
    })

    setIsOpen(!isOpen)
  }

  return (
    <AnimatedSurface
      mode={isOpen ? 'elevated' : 'flat'}
      style={[styles.container, animatedSurfaceStyles]}
    >
      <TouchableRipple
        accessibilityRole="button"
        onPress={() => toggleIsOpen()}
        style={styles.touchable}
      >
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <OrganizationAvatar
            logo={currentOrganization.logo}
            name={currentOrganization.name}
            size={40}
          />
          <View style={{ flex: 2 }}>
            <Text style={styles.title}>{currentOrganization.name}</Text>
            <Caption style={styles.caption}>
              {currentUserQuery.data?.firstName}{' '}
              {currentUserQuery.data?.lastName}
            </Caption>
          </View>
          <View style={{ flex: 0.4 }}>
            <Animated.View style={[styles.chevron, animatedIconStyles]}>
              <Icon
                color={theme.colors.outline}
                size={25}
                source={'chevron-down'}
              />
            </Animated.View>
          </View>
        </View>
      </TouchableRipple>
      {isOpen && (
        <Animated.View style={[{ margin: 5 }, animatedTextStyles]}>
          <Caption>Your Organizations</Caption>
          {organizations?.map((organization) => (
            <View key={organization.name}>
              <TouchableRipple
                accessibilityRole="button"
                onPress={() => {
                  if (organization.id !== currentOrganization.id) {
                    setCurrentOrganization({
                      id: organization.id,
                      logo: organization.logo || '',
                      name: organization.name,
                    })
                  }

                  toggleIsOpen()
                }}
                style={styles.touchableMenuItem}
              >
                <Animated.View
                  style={[
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                    },
                    animatedTextStyles,
                  ]}
                >
                  <OrganizationAvatar
                    logo={organization.logo}
                    name={organization.name}
                    size={30}
                  />
                  <Text style={styles.title}>{organization.name}</Text>
                  <IconButton
                    icon="cog"
                    onPress={() => router.push(`/organization/settings`)}
                    style={{}}
                  />
                </Animated.View>
              </TouchableRipple>

              <Divider style={{ marginTop: 5, marginBottom: 5 }} />
            </View>
          ))}
          <TouchableRipple
            accessibilityRole="button"
            onPress={() => {
              console.log('Not Implemented')
            }}
            style={styles.touchableMenuItem}
          >
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Icon
                color={theme.colors.outline}
                size={30}
                source="plus-box-outline"
              />
              <Text style={styles.newOrgTitle}>Create Organization</Text>
            </View>
          </TouchableRipple>
        </Animated.View>
      )}
    </AnimatedSurface>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  caption: {
    fontSize: 14,
    lineHeight: 14,
  },
  chevron: {
    alignItems: 'center',
    flex: 0,
    justifyContent: 'center',
    marginEnd: 10,
  },
  container: {
    backgroundColor: theme.colors.background,
    borderRadius: 10,
    borderWidth: 0,
    left: 0,
    margin: 10,
    position: 'absolute',
    right: 0,
    top: 80,
    zIndex: 100,
  },
  newOrgTitle: {
    marginLeft: 10,
  },
  title: {
    fontWeight: 'bold',
  },
  touchable: {
    borderRadius: 10,
    margin: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
  touchableMenuItem: {
    borderRadius: 10,
    padding: 10,
  },
}))

export default OrganizationPicker
