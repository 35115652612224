import { warmCachingFromAsyncStorage } from '@statsig/expo-bindings'
import { StatsigClient } from '@statsig/js-client'
import Constants from 'expo-constants'
import * as Device from 'expo-device'
import { Platform } from 'react-native'
import { isMaestro } from 'react-native-is-maestro'

export default function initStatsigClient() {
  const statsigToken = process.env.EXPO_PUBLIC_STATSIG_SDK_KEY

  if (!statsigToken) {
    throw new Error('Statsig SDK key not found')
  }

  const statsigClient = new StatsigClient(
    statsigToken,
    {
      appVersion: Constants.expoConfig?.version,
      custom: {
        e2eTest: process.env.EXPO_PUBLIC_E2E_TEST === 'true' || isMaestro(),
        platform: Platform.OS,
        brand: Device.brand || 'unknown',
        designName: Device.designName || 'unknown',
        deviceName: Device.deviceName || 'unknown',
        deviceType: Device.deviceType || 'unknown',
        manufacturer: Device.manufacturer || 'unknown',
        model: Device.modelName || 'unknown',
        modelId: Device.modelId || 'unknown',
        modelName: Device.modelName || 'unknown',
        osName: Device.osName || 'unknown',
        osVersion: Device.osVersion || 'unknown',
        platformApiLevel: Device.platformApiLevel || 'unknown',
        productName: Device.productName || 'unknown',
        supportedCpuArchitectures:
          Device.supportedCpuArchitectures || 'unknown',
      },
    },
    {
      environment: {
        tier: process.env.EXPO_PUBLIC_CHANNEL || process.env.NODE_ENV,
      },
    }
  )
  const statsigCacheWarming = warmCachingFromAsyncStorage(statsigClient)

  return { statsigClient, statsigCacheWarming }
}
