import { BottomSheetScrollView } from '@gorhom/bottom-sheet'
import React, { useCallback, useMemo } from 'react'
import {
  Control,
  Controller,
  useFieldArray,
  useForm,
  UseFormSetValue,
} from 'react-hook-form'
import { View } from 'react-native'
import { Button, IconButton } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { useAppStore } from '../../store/useAppStore'
import FormFieldDate from '../form/FormFieldDate'
import FormFieldSelect from '../form/FormFieldSelect'
import FormFieldText from '../form/FormFieldText'

export enum FilterType {
  Date = 'date',
  String = 'string',
  Boolean = 'boolean',
  Enum = 'enum',
}

export enum FilterOperator {
  GreaterThan = 'gt',
  LessThan = 'lt',
  Equals = 'eq',
  NotEquals = 'ne',
  Contains = 'ctn',
  DoesNotContain = 'not_ctn',
}

const operators = {
  [FilterType.Date]: [
    { label: 'is after', value: FilterOperator.GreaterThan },
    { label: 'is before', value: FilterOperator.LessThan },
    { label: 'exists', value: FilterOperator.NotEquals },
    { label: 'does not exist', value: FilterOperator.Equals },
  ],
  [FilterType.Boolean]: [
    { label: 'is true', value: FilterOperator.Equals },
    { label: 'is false', value: FilterOperator.NotEquals },
  ],
  [FilterType.String]: [
    { label: 'contains', value: FilterOperator.Contains },
    { label: 'does not contain', value: FilterOperator.DoesNotContain },
    { label: 'exists', value: FilterOperator.NotEquals },
    { label: 'does not exist', value: FilterOperator.Equals },
  ],
  [FilterType.Enum]: [
    { label: 'is', value: FilterOperator.Equals },
    { label: 'is not', value: FilterOperator.NotEquals },
  ],
}

const filterSchema = [
  { title: 'Adopted', field: 'isAdopted', type: FilterType.Boolean },
  // {
  //   title: 'Adopted Name',
  //   field: 'adoptions.adoptedName',
  //   type: FilterType.String,
  // },
  // {
  //   title: 'Adoption Date',
  //   field: 'adoptions.0.adoptionDate',
  //   type: FilterType.Date,
  // },
  // {
  //   title: 'Adoption Return Date',
  //   field: 'adoptions.0.returnDate',
  //   type: FilterType.Date,
  // },
  // {
  //   title: 'Adopter Email',
  //   field: 'adoptions.adoptedByEmail',
  //   type: FilterType.String,
  // },
  // {
  //   title: 'Adopter Name',
  //   field: 'adoptions.adoptedByName',
  //   type: FilterType.String,
  // },
  { title: 'Birth Date', field: 'birthDate', type: FilterType.Date },
  { title: 'Breed', field: 'breed', type: FilterType.String },
  {
    title: 'Combo Testing Completed',
    field: 'comboTestingCompletedDate',
    type: FilterType.Date,
  },
  { title: 'Deceased Date', field: 'deceasedDate', type: FilterType.Date },
  {
    title: 'Fixed Before Intake',
    field: 'fixedBeforeIntake',
    type: FilterType.Boolean,
  },
  { title: 'Fixed On', field: 'fixedOnDate', type: FilterType.Date },
  {
    title: 'Flea/Tick Meds Due',
    field: 'nextFleaTickMedDue',
    type: FilterType.Date,
  },
  // { title: 'Gender', field: 'gender', type: FilterType.Enum, enumType: Gender },
  {
    title: 'Heartworm Meds Due',
    field: 'nextHeartwormMedDue',
    type: FilterType.Date,
  },
  {
    title: 'Heartworm Positive',
    field: 'heartwormPositive',
    type: FilterType.Boolean,
  },
  {
    title: 'Heartworm Testing Complete',
    field: 'heartwormTestingCompletedDate',
    type: FilterType.Date,
  },
  { title: 'Hospice', field: 'isInHospice', type: FilterType.Boolean },
  { title: 'Intake Date', field: 'intakeDate', type: FilterType.Date },
  { title: 'Microchip Number', field: 'microchipId', type: FilterType.String },
  { title: 'Name', field: 'name', type: FilterType.String },
  {
    title: 'Ready For Adoption',
    field: 'readyForAdoption',
    type: FilterType.Boolean,
  },
  // {
  //   title: 'Species',
  //   field: 'species',
  //   type: FilterType.Enum,
  //   enumType: Species,
  // },
  { title: 'Updated At', field: 'updatedAt', type: FilterType.Date },
  { title: 'Updated By', field: 'updatedBy', type: FilterType.String },
  // { title: 'Volunteer Name', field: 'userMeta.name', type: FilterType.String },
]

interface Field {
  title: string
  field: string
  type: FilterType
}

interface FilterItem {
  field: string
  operator: string
  value: string | boolean | Date | null
}

interface FormValues {
  filters: FilterItem[]
}

const AdvancedFilterRow: React.FC<{
  control: Control<FormValues>
  id: string
  index: number
  remove: (index: number) => void
  fields: Field[]
  setValue: UseFormSetValue<FormValues>
}> = ({ control, index, remove, fields, setValue, id }) => {
  const { styles } = useStyles(stylesheet)

  const fieldOptions = fields.map((f) => ({ label: f.title, value: f.field }))

  return (
    <View key={id} style={styles.row}>
      <View style={styles.rowColumn}>
        {/* Field Selector */}
        <FormFieldSelect
          control={control}
          data={fieldOptions}
          dropdownPosition="top"
          fieldName={`filters.${index}.field`}
          label="Select Field"
          required
          style={styles.dropdown}
        />
        {/* Operator Selector */}
        <Controller
          control={control}
          name={`filters.${index}.field`}
          render={({ field: { value: selectedFieldName } }) => {
            const selectedField = fields.find(
              (f) => f.field === selectedFieldName
            )

            const operatorOptions =
              operators[selectedField?.type || FilterType.String]
            return (
              <FormFieldSelect
                control={control}
                data={operatorOptions}
                dropdownPosition="top"
                fieldName={`filters.${index}.operator`}
                label="Select Operator"
                required
                style={styles.dropdown}
              />
            )
          }}
        />
        {/* Value Input */}
        <Controller
          control={control}
          name={`filters.${index}.field`}
          render={({ field: { value: selectedFieldName } }) => {
            const selectedField = fields.find(
              (f) => f.field === selectedFieldName
            )
            return (
              <Controller
                control={control}
                name={`filters.${index}.operator`}
                render={({ field: { value: selectedOperator } }) => {
                  const isExistsOperator = [
                    FilterOperator.Equals,
                    FilterOperator.NotEquals,
                  ].includes(selectedOperator as FilterOperator)

                  if (selectedOperator === '') {
                    return <></>
                  }

                  if (isExistsOperator) {
                    // Set value to null when 'exists' or 'does not exist' is selected
                    setValue(`filters.${index}.value`, null)
                    return <></>
                  }

                  switch (selectedField?.type) {
                    case FilterType.String:
                      return (
                        <FormFieldText
                          blurOnSubmit
                          control={control}
                          fieldName={`filters.${index}.value`}
                          label="Enter value"
                          required
                          style={styles.dropdown}
                        />
                      )
                    case FilterType.Date:
                      return (
                        <FormFieldDate
                          control={control}
                          fieldName={`filters.${index}.value`}
                          label="Select date"
                          required
                          style={styles.dropdown}
                        />
                      )
                    case FilterType.Boolean:
                      return <></>
                    default:
                      return <></>
                  }
                }}
              />
            )
          }}
        />
      </View>
      <IconButton
        icon="minus-circle"
        onPress={(e) => {
          remove(index)
        }}
        style={{
          alignSelf: 'center',
          justifyContent: 'center',
        }}
      />
    </View>
  )
}

const AdvancedFilterView = () => {
  const { styles } = useStyles(stylesheet)

  const basicFilters = useAppStore.use.basicFilters()
  const setBasicFilters = useAppStore.use.setBasicFilters()

  const advancedFilters = useAppStore.use.advancedFilters()
  const setAdvancedFilters = useAppStore.use.setAdvancedFilters()
  const resetAdvancedFilters = useAppStore.use.resetAdvancedFilters()

  const defaultFormValues = useMemo(() => {
    return {
      filters: Object.entries(advancedFilters.filters).map((field, index) => ({
        field: field[0],
        operator: field[1].operator,
        value: field[1].value,
      })),
    }
  }, [advancedFilters.filters])

  const { control, handleSubmit, setValue, reset } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: defaultFormValues,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filters',
  })

  const resetFormDefault = useCallback(() => {
    reset(defaultFormValues)

    for (const [index, _] of fields.entries()) {
      remove(index)
    }
    // we want to run this every time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data: FormValues) => {
    setAdvancedFilters({
      ...advancedFilters,
      filters: data.filters.reduce(
        (acc, f) => {
          acc[f.field] = {
            operator: f.operator,
            value: f.value,
            type: filterSchema.find((fs) => fs.field === f.field)?.type as
              | 'string'
              | 'boolean'
              | 'date',
          }
          return acc
        },
        {} as {
          [key: string]: {
            type: 'string' | 'boolean' | 'date'
            operator: string
            value: string | boolean | Date | null
          }
        }
      ),
    })
  }

  return (
    <BottomSheetScrollView contentContainerStyle={{ padding: 16 }}>
      <Button
        disabled={!advancedFilters.isDirty}
        icon="restore"
        onPress={() => {
          resetAdvancedFilters()
          resetFormDefault()
        }}
        style={styles.resetButton}
      >
        Reset Filters
      </Button>
      <Button
        icon="more"
        onPress={() => {
          setBasicFilters({
            ...basicFilters,
            isActive: true,
          })
          setAdvancedFilters({
            ...advancedFilters,
            isActive: false,
          })
        }}
        style={styles.resetButton}
      >
        Basic Filters
      </Button>
      {fields.map((field, index) => (
        <AdvancedFilterRow
          control={control}
          fields={filterSchema}
          id={field.id}
          index={index}
          key={field.id}
          remove={remove}
          setValue={setValue}
        />
      ))}
      <View style={styles.buttonContainer}>
        <Button
          mode="contained-tonal"
          onPress={() => append({ field: '', operator: '', value: '' })}
        >
          Add Filter
        </Button>
        <Button mode="contained" onPress={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </View>
    </BottomSheetScrollView>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    buttonContainer: {
      gap: theme.tokens.spacing[3],
      justifyContent: 'space-between',
      marginTop: theme.tokens.spacing[4],
      paddingBottom: theme.tokens.spacing[4],
    },
    dropdown: {
      flex: {
        xs: 1,
        sm: 0.3,
      },
    },
    resetButton: {
      alignSelf: 'flex-end',
    },
    row: {
      alignItems: {
        xs: 'stretch',
        sm: 'center',
      },
      flexDirection: 'row',
      gap: theme.tokens.spacing[3],
      marginBottom: theme.tokens.spacing[3],
    },
    rowColumn: {
      flex: 1,
      flexDirection: {
        xs: 'column',
        sm: 'row',
      },
      gap: theme.tokens.spacing[3],
    },
  }
})

export default AdvancedFilterView
