import { useLocalSearchParams } from 'expo-router'
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useClickOutside } from 'react-native-click-outside'
import { Button, IconButton, Text } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import formatDate from '../../utils/formatDate'

interface Props {
  isSaving: boolean
  updatedAt?: Date | null
  updatedBy?: string | null
}

const SaveStatus = ({ isSaving, updatedAt, updatedBy }: Props) => {
  const { styles } = useStyles(stylesheet)
  const [detailsVisible, setDetailsVisible] = useState(false)
  const [hasSaved, setHasSaved] = useState(false)
  const params = useLocalSearchParams<{ id: string }>()
  const id = params.id || ''
  const ref = useClickOutside(() => setDetailsVisible(false))

  useEffect(() => {
    setHasSaved(false)
  }, [id])

  useEffect(() => {
    if (isSaving) {
      setHasSaved(true)
    }
  }, [isSaving])

  return (
    <View style={styles.root}>
      {isSaving || hasSaved ? (
        <Button
          icon="cloud-check-outline"
          loading={isSaving}
          onPress={() => setDetailsVisible(!detailsVisible)}
          style={styles.button}
        >
          {isSaving ? 'Saving...' : 'Saved!'}
        </Button>
      ) : (
        <IconButton
          icon="cloud-check-outline"
          onPress={() => setDetailsVisible(!detailsVisible)}
        />
      )}

      <View ref={ref} style={styles.content(detailsVisible)}>
        {isSaving ? (
          <Text style={styles.text}>Save in progress...</Text>
        ) : (
          <>
            {hasSaved && (
              <Text style={[styles.text, styles.mb]}>
                All changes have been saved.
              </Text>
            )}
            <Text style={styles.text}>
              {!updatedBy && !updatedAt ? (
                <>No history available.</>
              ) : (
                <>
                  Last updated {updatedBy && <>by {updatedBy}</>} on{' '}
                  {formatDate(updatedAt, 'M/dd/yyyy, h:mm a')}
                </>
              )}
            </Text>
          </>
        )}
      </View>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  button: {
    minWidth: 110,
  },
  content: (visible) => ({
    display: visible ? 'flex' : 'none',
    padding: theme.tokens.spacing[4],
    position: 'absolute',
    top: 45,
    right: 0,
    backgroundColor: theme.colors.surface,
    width: 300,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 6px',
    borderRadius: theme.tokens.containerBorderRadius,
  }),
  mb: {
    marginBottom: theme.tokens.spacing[4],
  },
  root: {
    position: 'relative',
  },
  text: {
    textAlign: 'center',
  },
}))

export default SaveStatus
