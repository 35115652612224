import { useNavigation } from 'expo-router'
import { useEffect } from 'react'
import FosterPage from '../../../../components/fosters/FosterPage'
import FosterService from '../../../../services/fosterService'

const FosterDetail = () => {
  const navigation = useNavigation()

  const fosterName = FosterService.useCurrentFosterName()

  useEffect(() => {
    navigation.getParent()?.setOptions({
      headerBackVisible: true,
      title: fosterName || 'Add Foster',
    })
  })

  return <FosterPage />
}

export default FosterDetail
