import { useNavigation } from 'expo-router'
import { useCallback, useEffect } from 'react'
import FosterService from '../../services/fosterService'
import Page from '../shared/Page'
import FosterAdoptionCard from './cards/FosterAdoptionsCard'
import FosterDocumentsCard from './cards/FosterDocumentsCard'
import FosterInfoCard from './cards/FosterInfoCard'
import FosterMedicalCard from './cards/FosterMedicalCard'
import FosterNotesCard from './cards/FosterNotesCard'
import useFosterForm from './useFosterForm'

const FosterPage = () => {
  const navigation = useNavigation()

  const { fosterId } = FosterService.useCurrentFoster()
  const { refreshFoster } = FosterService.useFoster(fosterId)

  const fosterName = FosterService.useCurrentFosterName()

  const { control, formState, submit } = useFosterForm()

  useEffect(() => {
    navigation.setOptions({
      title: fosterName || 'Add Foster',
      headerBackVisible: true,
      showSearchBar: false,
    })
  }, [fosterName, navigation])

  const onRefresh = useCallback(async () => {
    await refreshFoster()
  }, [refreshFoster])

  return (
    <Page onRefresh={onRefresh} viewId="foster-detail-scroll-view">
      <FosterInfoCard control={control} formState={formState} submit={submit} />
      <FosterMedicalCard
        control={control}
        formState={formState}
        submit={submit}
      />
      <FosterNotesCard
        control={control}
        formState={formState}
        submit={submit}
      />
      <FosterAdoptionCard />
      <FosterDocumentsCard />
    </Page>
  )
}

export default FosterPage
