import { DrawerContentComponentProps } from '@react-navigation/drawer'
import { router } from 'expo-router'
import { Drawer, DrawerItemProps, Icon } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'

interface Props {
  icon: string
  label: string
  route: string
}

const DrawerItem = ({
  icon,
  label,
  route,
  state,
}: Props & DrawerItemProps & DrawerContentComponentProps) => {
  const { styles } = useStyles(stylesheet)

  const iconComponent = ({
    color,
    size,
  }: {
    color: string | undefined
    size: number
  }) => <Icon color={color} size={size} source={icon} />

  return (
    <Drawer.Item
      active={isActiveItem(route, state)}
      icon={iconComponent}
      label={label}
      onPress={() => {
        router.navigate(route)
      }}
      style={styles.root}
    />
  )
}

const normalizeRouteName = (routeName: string) => {
  return routeName
}

const isActiveItem = (route: string, state: any) => {
  const activeRouteName = normalizeRouteName(state.routeNames[state.index])
  return activeRouteName.startsWith(route)
}

const stylesheet = createStyleSheet((theme) => ({
  root: {
    borderBottomRightRadius: 28,
    borderColor: theme.colors.background,
    borderRadius: 0,
    borderTopRightRadius: 28,
    borderWidth: 1,
    marginLeft: 0,
    paddingLeft: theme.tokens.spacing[4],
  },
}))

export default DrawerItem
