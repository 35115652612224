import { StyleProp, View, ViewStyle } from 'react-native'
import LogoSVG from '../../../assets/logo.svg'

const Logo = ({ style }: { style: StyleProp<ViewStyle> }) => {
  return (
    <View style={style}>
      <LogoSVG testID="logo" viewBox="0 0 380 104" width="100%" />
    </View>
  )
}

export default Logo
