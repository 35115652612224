import { createStyleSheet } from 'react-native-unistyles'

export default createStyleSheet((theme) => {
  return {
    textInputContainer: {
      columnGap: theme.tokens.spacing[4],
      flexDirection: 'row',
      flexWrap: 'wrap',
      rowGap: theme.tokens.spacing[4] / 2,
    },
  }
})
