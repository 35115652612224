import { Control, FormState } from 'react-hook-form'
import { View } from 'react-native'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FosterService from '../../../services/fosterService'
import FormFieldText from '../../form/FormFieldText'
import Card from '../../shared/Card'
import { FosterForm } from '../fosterFormSchema'

interface Props {
  control: Control<FosterForm, any>
  formState: FormState<FosterForm>
  submit: () => void
}

const FosterNotesCard = ({ control, formState, submit }: Props) => {
  const { styles } = useStyles(stylesheet)

  const { fosterId } = FosterService.useCurrentFoster()
  const { isLoading } = FosterService.useFoster(fosterId)

  return (
    <View style={styles.historyAndNotesContainer}>
      <Card title="History & Training">
        <View
          style={{
            rowGap: 16,
          }}
        >
          <FormFieldText
            contentStyle={{ height: 150 }}
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="history"
            isLoading={isLoading}
            keyboardType="default"
            label="History"
            multiline={true}
            onBlur={submit}
            outlineStyle={{ height: 150 }}
            required={false}
            style={{
              height: 150,
              flexGrow: 1,
              minWidth: 150,
            }}
          />
          <FormFieldText
            contentStyle={{ height: 150 }}
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="training"
            isLoading={isLoading}
            keyboardType="default"
            label="Training"
            multiline={true}
            onBlur={submit}
            outlineStyle={{ height: 150 }}
            required={false}
            style={{
              height: 150,
              flexGrow: 1,
              minWidth: 150,
            }}
          />
        </View>
      </Card>
      <Card title="Notes">
        <FormFieldText
          contentStyle={{ height: 316 }}
          control={control}
          disabled={isLoading}
          errors={formState.errors}
          fieldName="notes"
          isLoading={isLoading}
          keyboardType="default"
          label="Notes"
          multiline={true}
          onBlur={submit}
          outlineStyle={{ height: 316 }}
          required={false}
          style={{
            height: 316,
            flexGrow: 1,
            minWidth: 150,
          }}
        />
      </Card>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    historyAndNotesContainer: {
      columnGap: theme.tokens.spacing[4],
      flexDirection: {
        sm: 'column',
        md: 'row',
      },
      rowGap: theme.tokens.spacing[4],
    },
  }
})

export default FosterNotesCard
