import { Control, FormState } from 'react-hook-form'
import { View } from 'react-native'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FosterService from '../../../services/fosterService'
import FormFieldDate from '../../form/FormFieldDate'
import FormFieldText from '../../form/FormFieldText'
import Card from '../../shared/Card'
import { FosterForm } from '../fosterFormSchema'
import FosterThumbnailEdit from '../FosterThumbnailEdit'
import fosterSharedStyles from './fosterSharedStyles'

interface Props {
  control: Control<FosterForm, any>
  formState: FormState<FosterForm>
  submit: () => void
}

const FosterInfoCard = ({ control, formState, submit }: Props) => {
  const { styles } = useStyles(stylesheet)
  const { styles: sharedStyles } = useStyles(fosterSharedStyles)

  const { fosterId } = FosterService.useCurrentFoster()
  const { isLoading } = FosterService.useFoster(fosterId)

  return (
    <View style={styles.detailContainer}>
      <FosterThumbnailEdit fosterId={fosterId} />
      <Card title="Foster Details">
        <View style={sharedStyles.textInputContainer}>
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="name"
            isLoading={isLoading}
            label="Name"
            onBlur={submit}
            required={true}
            style={[styles.fosterDetailInput, styles.nameFlexBasis]}
          />
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="species"
            isLoading={isLoading}
            label="Species"
            onBlur={submit}
            required={false}
            style={[styles.fosterDetailInput, styles.speciesFlexBasis]}
          />
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="user"
            isLoading={isLoading}
            label="Volunteer"
            onBlur={submit}
            required={false}
            style={[styles.fosterDetailInput, styles.volunteerFlexBasis]}
          />
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="gender"
            isLoading={isLoading}
            label="Gender"
            onBlur={submit}
            required={false}
            style={styles.fosterDetailInput}
          />
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="breed"
            isLoading={isLoading}
            label="Breed"
            onBlur={submit}
            required={false}
            style={styles.fosterDetailInput}
          />
          <FormFieldDate
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="birthDate"
            isLoading={isLoading}
            label="Birth Date"
            onBlur={submit}
            required={false}
            style={styles.fosterDetailInput}
          />
          <FormFieldDate
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="intakeDate"
            isLoading={isLoading}
            label="Intake Date"
            onBlur={submit}
            required={false}
            style={styles.fosterDetailInput}
          />
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="currentFood"
            isLoading={isLoading}
            label="Current Food"
            onBlur={submit}
            required={false}
            style={styles.fosterDetailInput}
          />
          <FormFieldText
            control={control}
            disabled={isLoading}
            errors={formState.errors}
            fieldName="weight"
            isLoading={isLoading}
            keyboardType="numeric"
            label="Weight"
            onBlur={submit}
            required={false}
            style={styles.fosterDetailInput}
          />
        </View>
      </Card>
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    detailContainer: {
      columnGap: theme.tokens.spacing[4],
      flexDirection: {
        sm: 'column',
        md: 'row',
      },
      rowGap: theme.tokens.spacing[4],
    },
    fosterDetailInput: {
      flexBasis: {
        xs: '40%',
        md: '30%',
      },
      flexGrow: 1,
      minWidth: 125,
    },
    nameFlexBasis: {
      flexBasis: {
        xs: '60%',
        md: '30%',
      },
    },
    speciesFlexBasis: {
      flexBasis: {
        xs: '20%',
        md: '10%',
      },
    },
    volunteerFlexBasis: {
      flexBasis: {
        xs: '100%',
        md: '65%',
        lg: '80%',
        xl: '40%',
      },
    },
  }
})

export default FosterInfoCard
