import { useNavigation } from 'expo-router'
import { useCallback, useEffect, useState } from 'react'
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  View,
} from 'react-native'
import { RefreshControl } from 'react-native-gesture-handler'
import { AnimatedFAB } from 'react-native-paper'
import { FlatGrid } from 'react-native-super-grid'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import FosterGridItem from '../../../components/fosters/FosterGridItem'
import NewFoster from '../../../components/fosters/NewFoster'
import FosterService from '../../../services/fosterService'
import { useAppStore } from '../../../store/useAppStore'

const Grid = () => {
  const navigation = useNavigation()
  const { styles } = useStyles(stylesheet)
  const [isRefreshing, setRefreshing] = useState(false)
  const [isFABExtended, setIsFABExtended] = useState(true)
  const [isNewFosterSheetVisible, setIsNewFosterSheetVisible] = useState(false)

  const { fetchNextPage, fosterList, refreshFosterList } =
    FosterService.useFosterList()

  const basicFilters = useAppStore.use.basicFilters()

  useEffect(() => {
    navigation.setOptions({
      title: basicFilters.filters.viewAll ? 'All Fosters' : 'My Fosters',
    })
  }, [navigation, basicFilters.filters.viewAll])

  const onRefresh = useCallback(async () => {
    setRefreshing(true)

    await refreshFosterList()

    setRefreshing(false)
  }, [refreshFosterList])

  const onScroll = ({
    nativeEvent,
  }: NativeSyntheticEvent<NativeScrollEvent>) => {
    const currentScrollPosition = Math.floor(nativeEvent?.contentOffset?.y) ?? 0

    setIsFABExtended(currentScrollPosition <= 500)
  }

  return (
    <View style={styles.root}>
      <FlatGrid
        contentInsetAdjustmentBehavior="automatic"
        data={fosterList}
        itemDimension={175}
        keyExtractor={(foster) => foster.id}
        onEndReached={() => {
          fetchNextPage()
        }}
        onEndReachedThreshold={0.5}
        onScroll={onScroll}
        refreshControl={
          <RefreshControl onRefresh={onRefresh} refreshing={isRefreshing} />
        }
        renderItem={({ item: foster }) => <FosterGridItem foster={foster} />}
        style={{ flex: 1 }}
        testID="foster-grid"
      />
      <AnimatedFAB
        animateFrom="right"
        extended={isFABExtended}
        icon="plus"
        iconMode="dynamic"
        // This looks stupid, but it works around a bug on iOS where the label isn't spaced correctly. So we add a single whitespace character to the label.
        label={Platform.OS === 'ios' ? 'Add a Foster ' : 'Add a Foster'}
        onPress={() => setIsNewFosterSheetVisible(true)}
        style={styles.fabStyle}
      />
      <NewFoster
        onClose={() => setIsNewFosterSheetVisible(false)}
        open={isNewFosterSheetVisible}
      />
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    fabStyle: {
      bottom: 32,
      position: 'absolute',
      right: 24,
    },
    root: {
      backgroundColor: theme.colors.background,
      flex: 1,
    },
  }
})

export default Grid
