import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import FosterService from '../../services/fosterService'
import trpc from '../../utils/trpc'
import { prepareFormForSubmission } from '../form/formUtils'
import Snackbar from '../snackbar/Snackbar'
import fosterFormSchema, { FosterForm } from './fosterFormSchema'

const useFosterForm = () => {
  const { fosterId } = FosterService.useCurrentFoster()
  const { foster, refreshFoster } = FosterService.useFoster(fosterId)

  const fosterUpdateMutation = trpc.foster.update.useMutation()

  const fosterForm = useForm<FosterForm>({
    mode: 'onBlur',
    resolver: zodResolver(fosterFormSchema),
    defaultValues: {
      ...foster,
      weight: foster?.weight?.toString(),
      user: foster?.user?.id as string,
    },
  })

  const { control, formState, handleSubmit, reset } = fosterForm

  useEffect(() => {
    reset(
      {
        ...foster,
        weight: foster?.weight?.toString(),
        user: foster?.user?.id as string,
      },
      {
        keepDirtyValues: true,
      }
    )
  }, [foster, reset])

  const submit = () => {
    if (!Object.values(formState.dirtyFields).length) {
      return
    }

    handleSubmit((data) => {
      Snackbar.info(`Saving...`)

      fosterUpdateMutation.mutate(
        {
          ...prepareFormForSubmission(data),
          id: fosterId,
          userId: foster?.user?.id as string, // TODO: this should be dynamic
          vaccinations: [],
          weight: data.weight ? parseFloat(data.weight) : null,
        },
        {
          onError: (error) => {
            Snackbar.error('There was a problem saving this foster')
          },
          onSuccess: (updatedData) => {
            refreshFoster()
            reset(
              {
                ...updatedData,
                weight: updatedData.weight?.toString(),
                user: updatedData.user?.id as string,
              },
              {
                keepDirtyValues: true,
              }
            )
          },
        }
      )
    })()
  }

  return {
    control,
    formState,
    submit,
  }
}

export default useFosterForm
