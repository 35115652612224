import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  useDrawerProgress,
} from '@react-navigation/drawer'
import { useGateValue } from '@statsig/expo-bindings'
import { Platform } from 'react-native'
import { Drawer, IconButton, Text, Tooltip } from 'react-native-paper'
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { AppVersions, Feature } from '../../../constants'
import useShouldHideDrawer from '../../hooks/useShouldHideDrawer'
import { useAppStore } from '../../store/useAppStore'
import DrawerItem from './DrawerItem'
import Logo from './Logo'
import OrganizationPicker from './OrganizationPicker'

const DrawerContent = (props: DrawerContentComponentProps) => {
  const { styles } = useStyles(stylesheet)
  const drawerStore = useAppStore.use.drawer()
  const setDrawer = useAppStore.use.setDrawer()
  const shouldHideDrawer = useShouldHideDrawer()
  const { theme } = useStyles()
  const isEmployee = useGateValue(Feature.Employee)

  const progress = useDrawerProgress()

  const containerAnimatedStyle = useAnimatedStyle(() => ({
    overflow: 'hidden',
    height: '100%',
    opacity: interpolate(
      progress?.value || 0,
      [0, 1],
      [0, 1],
      Extrapolation.CLAMP
    ),
    transform: [
      {
        translateX: interpolate(
          progress?.value || 0,
          [0, 1],
          [theme.tokens.drawerWidth, 0],
          Extrapolation.CLAMP
        ),
      },
    ],
  }))

  const sectionAnimatedStyle = useAnimatedStyle(() => ({
    flex: 1,
    opacity: interpolate(
      progress?.value || 0,
      [0, 0.2],
      [0, 1],
      Extrapolation.CLAMP
    ),
    width: `${interpolate(
      progress?.value || 0,
      [0, 1],
      [0, 100],
      Extrapolation.CLAMP
    )}%`,
  }))

  return (
    <DrawerContentScrollView
      contentContainerStyle={{
        height: '100%',
      }}
      {...props}
    >
      <Animated.View style={containerAnimatedStyle}>
        <Drawer.Section showDivider={false}>
          {!shouldHideDrawer && (
            <Tooltip
              title={drawerStore.isPinned ? 'Unpin Sidebar' : 'Pin Sidebar'}
            >
              <IconButton
                icon={drawerStore.isPinned ? 'pin-off-outline' : 'pin-outline'}
                iconColor={theme.colors.backdrop}
                onPress={() => {
                  setDrawer({ isPinned: !drawerStore.isPinned })
                }}
                style={styles.pinButton}
              />
            </Tooltip>
          )}
          <Logo style={styles.logoSection} />
        </Drawer.Section>

        <OrganizationPicker />

        <Animated.View
          needsOffscreenAlphaCompositing={true}
          renderToHardwareTextureAndroid={true}
          style={[{ zIndex: -1, marginTop: 40 }, sectionAnimatedStyle]}
        >
          <Drawer.Section showDivider={false} style={styles.drawerSection}>
            {routes.map((routeDef) => (
              <DrawerItem
                icon={routeDef.icon}
                key={routeDef.route}
                label={routeDef.label}
                route={routeDef.route}
                {...props}
              />
            ))}
          </Drawer.Section>
          <Drawer.Section showDivider={false} style={styles.versionSection}>
            <Text style={styles.versionText}>
              {Platform.OS === 'web'
                ? AppVersions.expoVersion
                : AppVersions.nativeVersion}
              -{AppVersions.gitSha}
            </Text>
            {isEmployee && (
              <>
                <Text style={styles.versionText}>
                  Git Sha: {AppVersions.gitSha}
                </Text>
                <Text style={styles.versionText}>
                  Native Version: {AppVersions.nativeVersion}
                </Text>
                <Text style={styles.versionText}>
                  JS Bundle Version: {AppVersions.expoVersion}
                </Text>
                {Platform.OS !== 'web' && (
                  <Text style={styles.versionText}>
                    Runtime Version: {AppVersions.runtimeVersion?.slice(0, 9)}
                  </Text>
                )}
              </>
            )}
          </Drawer.Section>
        </Animated.View>
      </Animated.View>
    </DrawerContentScrollView>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  drawerSection: {
    marginTop: 20,
    zIndex: -1,
  },
  logoSection: {
    alignContent: 'center',
    flexBasis: 100,
    flexShrink: 1,
    justifyContent: 'center',
    marginLeft: 40,
    marginRight: 40,
  },
  pinButton: {
    position: 'absolute',
  },
  versionSection: {
    alignSelf: 'center',
    bottom: 10,
    flex: 1,
    position: 'absolute',
  },
  versionText: {
    color: theme.colors.outlineVariant,
  },
}))

const routes = [
  {
    icon: 'dog',
    label: 'Fosters',
    route: 'fosters',
  },
  {
    icon: 'account',
    label: 'Users',
    route: 'users',
  },
]

export default DrawerContent
