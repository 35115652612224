import { datadogRum } from '@datadog/browser-rum'
import Constants from 'expo-constants'
import { AppVersions } from '../../../constants'

export async function evaluateFeature(feature: string, evaluation: any) {
  datadogRum.addFeatureFlagEvaluation(feature, evaluation)
}

export async function initialize() {
  datadogRum.init({
    applicationId: process.env.EXPO_PUBLIC_WEB_DATADOG_APPLICATION_ID || '',
    clientToken: process.env.EXPO_PUBLIC_WEB_DATADOG_CLIENT_TOKEN || '',
    enableExperimentalFeatures: ['feature_flags'],
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'rescuebase-web',
    env: process.env.NODE_ENV || 'local',
    version: `${AppVersions.expoVersion}-${AppVersions.gitSha}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

export function setUser(user: {
  id: string
  email: string
  firstName: string
  lastName: string
}) {
  datadogRum.setUser({
    email: user.email,
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
  })
}

export function stop() {
  datadogRum.stopSession()
}

export function trackPageView(viewkey: string, pathname: string) {
  //noop for web
}
