import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
} from 'react-hook-form'
import { View } from 'react-native'
import { HelperText, TextInputProps } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import Skeleton from '../shared/Skeleton'
import { getErrorField } from './formUtils'
import TextInput from './TextInput'

interface Props<C extends FieldValues, E extends FieldErrors> {
  fieldName: Path<C>
  required: boolean
  errors?: E
  control: Control<C, any>
  isLoading?: boolean
  onBlur?: () => void
}

const FormFieldText = <C extends FieldValues, E extends FieldErrors>({
  fieldName,
  required,
  errors,
  control,
  isLoading,
  style,
  onBlur,
  ...props
}: Props<C, E> & TextInputProps) => {
  const { styles } = useStyles(stylesheet)

  return (
    <View style={style}>
      {/* <Skeleton show={isLoading}> */}
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, onBlur: formOnBlur, value } }) => (
          <View {...props}>
            <TextInput
              accessibilityHint="This is a text input field"
              accessibilityLabel="Text input field"
              testID={`${fieldName}-textInput`}
              {...props}
              label={props.label as string}
              // error={!!getErrorField(fieldName, errors)}
              multiline={props.multiline}
              onBlur={() => {
                onBlur?.()
                formOnBlur()
              }}
              onChangeText={onChange}
              style={styles.root}
              value={value || ''}
            />
            {getErrorField(fieldName, errors) && (
              <HelperText
                padding="normal"
                type="error"
                visible={!!getErrorField(fieldName, errors)}
              >
                {getErrorField(fieldName, errors)?.message as string}
              </HelperText>
            )}
          </View>
        )}
        rules={{
          required: required,
        }}
      />
      {/* </Skeleton> */}
    </View>
  )
}

const stylesheet = createStyleSheet((theme) => ({
  root: {
    backgroundColor: theme.colors.surface,
    flexWrap: 'nowrap',
  },
}))

export default FormFieldText
