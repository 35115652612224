import { router } from 'expo-router'
import { useCallback } from 'react'
import { Alert } from 'react-native'
import FosterService from '../../../services/fosterService'

const useDeleteAction = () => {
  const { deleteFoster: fosterServiceDelete, isDeleting } =
    FosterService.useDeleteFoster()

  const deleteFoster = useCallback(() => {
    Alert.alert(
      'Delete',
      'Are you sure you want to delete this foster? This will delete anything related to this foster including:\n\n- medical information\n- notes\n-documents\n-all photos\n- adoption records\n-microchip information\n\nThis cannot be undone!',
      [
        {
          text: 'Cancel',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          isPreferred: false,
          text: 'Delete',
          onPress: async () => {
            await fosterServiceDelete()
            router.replace('/fosters')
          },
          style: 'destructive',
        },
      ]
    )
  }, [fosterServiceDelete])

  return { deleteFoster, isDeleting }
}

export default useDeleteAction
