import { NativeStackHeaderProps } from '@react-navigation/native-stack'
import { Stack } from 'expo-router'
import { useStyles } from 'react-native-unistyles'
import Header from '../../../components/navigation/Header'

const HeaderComponent = (props: NativeStackHeaderProps) => {
  return <Header {...props} />
}

const UserLayout = () => {
  const { theme } = useStyles()

  return (
    <Stack
      screenOptions={{
        contentStyle: { backgroundColor: theme.colors.background },
        header: HeaderComponent,
      }}
    />
  )
}

export default UserLayout
